<template>
  <SollyLandingPage />
</template>

<script>
import SollyLandingPage from './components/SollyLandingPage.vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'App',
  components: {
    SollyLandingPage,
  },
  setup() {
    useHead({
      title: 'AI SOLLY - 社交練習小夥伴',
      meta: [
        { charset: 'UTF-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'keywords', content: 'PolaKids 波拉星 - 教育科技的北極星' },
        { name: 'description', content: 'PolaKids APP 如同家庭教育的北極星，指引親子走向適性的教養之路！專為 4-12 歲孩童父母所設計的親子教育方針，提供父母最適切的教育方式，並輕鬆融入在生活中。透過性格分析、性格任務卡及生活常規培養集點等，帶入玩中學的概念，孩子更樂於挑戰成長！現在就開始讓你的教育之路，展開更輕鬆愉快的體驗吧！' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'apple-touch-fullscreen', content: 'yes' },
        { name: 'csrf-token', content: '' },
        { property: 'og:title', content: 'PolaKids 波拉星 - 教育科技的北極星' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://solly.polakids.com.tw/' },
        { property: 'og:image', content: 'https://aisolly.polakids.com.tw/favicon.ico' },
        { property: 'fb:app_id', content: '' },
        { property: 'og:description', content: 'PolaKids APP 如同家庭教育的北極星，指引親子走向適性的教養之路！專為 4-12 歲孩童父母所設計的親子教育方針，提供父母最適切的教育方式，並輕鬆融入在生活中。透過性格分析、性格任務卡及生活常規培養集點等，帶入玩中學的概念，孩子更樂於挑戰成長！現在就開始讓你的教育之路，展開更輕鬆愉快的體驗吧！' },
        { property: 'og:site_name', content: 'PolaKids 波拉星 - 教育科技的北極星' },
        { name: 'author', content: 'PolaKids' },
        { name: 'copyright', content: 'PolaKids' },
        { name: 'distribution', content: 'Taiwan' },
        { name: 'robots', content: '' },
        { name: 'language', content: 'zh-tw' },
        { name: 'revisit-after', content: 'days' },
        { property: 'og:latitude', content: '' },
        { property: 'og:longitude', content: '' },
        { property: 'og:street-address', content: '' },
        { property: 'og:locality', content: 'Taipei' },
        { property: 'og:region', content: 'Taipei' },
        { property: 'og:postal-code', content: '' },
        { property: 'og:country-name', content: 'Taiwan' },
        { property: 'og:email', content: 'gili7lucky@gmail.com' },
        { property: 'og:phone_number', content: '' },
        { property: 'og:fax_number', content: '' },
        { name: 'twitter:card', content: '' },
        { name: 'twitter:title', content: 'PolaKids 波拉星 - 教育科技的北極星' },
        { name: 'twitter:description', content: 'PolaKids APP 如同家庭教育的北極星，指引親子走向適性的教養之路！專為 4-12 歲孩童父母所設計的親子教育方針，提供父母最適切的教育方式，並輕鬆融入在生活中。透過性格分析、性格任務卡及生活常規培養集點等，帶入玩中學的概念，孩子更樂於挑戰成長！現在就開始讓你的教育之路，展開更輕鬆愉快的體驗吧！' },
        { name: 'twitter:creator', content: '' },
      ],
      link: [
        { rel: 'author', href: 'PolaKids' },
        { rel: 'publisher', href: '' },
        { rel: 'Shortcut Icon', type: 'image/x-icon', href: 'https://aisolly.polakids.com.tw/favicon.ico' },
        { rel: 'Bookmark', type: 'image/x-icon', href: 'https://aisolly.polakids.com.tw/favicon.ico' },
        { rel: 'apple-touch-icon', type: 'image/x-icon', href: 'https://aisolly.polakids.com.tw/favicon.ico' },
      ],
      htmlAttrs: {
        'apple-mobile-web-app-title': 'PolaKids 波拉星 - 教育科技的北極星',
        'apple-mobile-web-app-status-bar-style': 'black',
        'format-detection': 'telephone=no,address=no'
      }
    });
  }
};
</script>

<style>
/* Add any global styles here */
</style>
