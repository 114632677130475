<template>
  <div style="background-color:rgba(218, 218, 218, 1);">
    <header id="solly-universe" class="flex overflow-hidden relative z-30 flex-col w-full md:text-base lg:text-xl xl:text-2xl max-xl:text-2xl font-sans leading-5 text-white max-md:max-w-full">
        <!-- 電腦/平板 -->
        <nav ref="desktopNav" class="hidden md:flex md:fixed md:justify-between md:items-start md:px-[4%] md:py-[1.5%] md:w-full md:bg-black">
          <img src="../../public/imges/Sollyicon.png" alt="Logo" class=" lazy shrink-0 max-w-full w-[15%] h-[5%] cursor-pointer" @click="location('Home')" />
          <div class="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full">
            <div tabindex="0" class="hover:border-b-2 cursor-pointer" @click="scrollToSection('solly-universe')">SOLLY 宇宙</div>
            <div tabindex="0" class="hover:border-b-2 cursor-pointer" @click="scrollToSection('solly-specialpower')">SOLLY 的特殊力</div>
            <div tabindex="0" class="hover:border-b-2 cursor-pointer" @click="scrollToSection('solly-journey')">SOLLY 的旅程</div>
            <div tabindex="0" class="hover:border-b-2 cursor-pointer" @click="scrollToSection('our-story')">我們的故事</div>
            <div tabindex="0" class="hover:border-b-2 cursor-pointer" @click="scrollToSection('contact-us')">CONTACT US</div>
          </div>
        </nav>
        <!-- 手機 -->
        <nav ref="mobileNav" class="flex fixed justify-between items-start px-[5%] py-[2.5%] w-full bg-black font-black text-xs text-right md:hidden">
          <img src="../../public/imges/Sollyicon.png" alt="Logo" class=" lazy shrink-0 max-w-full w-[25%] h-[15%] mt-[1%]" @click="location('Home')"/>
          <img src="../../public/imges/mobile-menu.png" @click="toggleMobileMenu" alt="ICON" class="lazy w-[5%] cursor-pointer" />
          <div v-if="mobileMenuOpen" class="flex flex-col fixed right-0 top-[4.8%] space-y-3 px-3 py-3 bg-white text-black">
            <div class="cursor-pointer" @click="scrollToSection('solly-universe')"><span class="hover:border-b-2 border-black">SOLLY 宇宙</span></div>
            <div class="cursor-pointer" @click="scrollToSection('solly-specialpower')"><span class="hover:border-b-2 border-black">SOLLY 的特殊力</span></div>
            <div class="cursor-pointer" @click="scrollToSection('solly-journey')"><span class="hover:border-b-2 border-black">SOLLY 的旅程</span></div>
            <div class="cursor-pointer" @click="scrollToSection('our-story')"><span class="hover:border-b-2 border-black">我們的故事</span></div>
            <div class="cursor-pointer" @click="scrollToSection('contact-us')"><span class="hover:border-b-2 border-black">CONTACT US</span></div>
          </div>
        </nav>
    </header>
    <main class="relative mx-auto overflow-x-hidden">
      <img src="../../public/imges/bg1.png" alt="Background image" class="lazy size-full mt-8" />
      <!-- 1 -->
      <div class="relative">
        <!-- 背景圖 -->
        <div class="relative w-full">
          <img src="../../public/imges/bg2-1.png" style="width: 100%; height: auto;" class="lazy">
        </div>
        <div class="absolute w-full top-0">
          <!-- Unique Title -->
          <div class="relative mt-[15%] mx-[10%]">
              <img src="../../public/imges/text.png" width="65%" height="auto" class="lazy relative mx-auto" />
          </div>
          <!-- 情緒介紹 -->
          <div class="grid grid-cols-12 gap-2 mx-[16%] mt-[5%] justify-center sm:gap-12">
            <!-- ANLLY -->
            <div class="col-span-6">
              <img src="../../public/imges/ANLLY.png" width="100%" height="auto" class="lazy relative mx-auto cursor-pointer" @click="showImage(0)" />
              <transition name="fade">
                <div
                  v-if="isImageVisible && currentIndex === 0"
                  class="fixed z-50 inset-0 bg-black bg-opacity-75 flex items-center justify-center"
                  @click.self="hideImage"
                >
                  <img src="../../public/imges/emotion1.png" width="70%" height="auto" class="lazy max-w-full max-h-full" />
                </div>
              </transition>
            </div>
            <!-- DILLY -->
            <div class="col-span-6">
              <img src="../../public/imges/DILLY.png" width="100%" height="auto" class="lazy relative mx-auto cursor-pointer" @click="showImage(1)"/>
              <transition name="fade">
                <div
                  v-if="isImageVisible && currentIndex === 1"
                  class="fixed z-50 inset-0 bg-black bg-opacity-75 flex items-center justify-center"
                  @click.self="hideImage"
                >
                  <img src="../../public/imges/emotion2.png" width="70%" height="auto" class="lazy max-w-full max-h-full" />
                </div>
              </transition>
            </div>
            <!-- LOLLY -->
            <div class="col-span-6">
              <img src="../../public/imges/LOLLY.png" width="100%" height="auto" class="lazy relative mx-auto cursor-pointer" @click="showImage(2)"/>
              <transition name="fade">
                <div
                  v-if="isImageVisible && currentIndex === 2"
                  class="fixed z-50 inset-0 bg-black bg-opacity-75 flex items-center justify-center"
                  @click.self="hideImage"
                >
                  <img src="../../public/imges/emotion3.png" width="70%" height="auto" class="lazy max-w-full max-h-full" />
                </div>
              </transition>
            </div>
            <!-- GALLY -->
            <div class="col-span-6">
              <img src="../../public/imges/GALLY.png" width="100%" height="auto" class="lazy relative mx-auto cursor-pointer" @click="showImage(3)"/>
              <transition name="fade">
                <div
                  v-if="isImageVisible && currentIndex === 3"
                  class="fixed z-50 inset-0 bg-black bg-opacity-75 flex items-center justify-center"
                  @click.self="hideImage"
                >
                  <img src="../../public/imges/emotion4.png" width="70%" height="auto" class="lazy max-w-full max-h-full" />
                </div>
              </transition>
            </div>
          </div>
          <div class="relative mt-[10%] mx-[10%]">
              <img src="../../public/imges/text2.png" width="50%" height="auto" class="lazy relative mx-auto" />
          </div>
          <!-- IMG -->
          <div class="relative mx-[15%] mt-[5%]">
            <img src="../../public/imges/AISOLLYcommunicate.png" width="100%" height="auto" class="lazy relative mx-auto" />
            <div class=" absolute -top-[55%] md:-top-[55%] lg:-top-[50%] xl:-top-[40%] -left-[30%]  md:-left-[35%] lg:-left-[30%] xl:-left-[25%]">
              <img src="../../public/imges/yellostars.png" height="auto" class="lazy relative w-[35%] md:w-[45%] lg:w-[60%] xl:w-[70%] max-w-[70%]" />
            </div>
          </div>
          <!-- AISOLLY介紹 -->
          <div class="relative">
            <div class="relative mx-[15%] mt-[10%]">
              <img src="../../public/imges/bg3.png" style="width: 100%; height: auto;" class="lazy">
            </div>
          </div>
        </div>
      </div>
      <!-- 2 -->
      <div class="relative">
        <!-- 背景圖 -->
        <div class="relative">
          <img src="../../public/imges/bg2-2.png" style="width: 100%; height: auto;" class="lazy">
        </div>
        <div class=" absolute top-[0.5%] w-full" id="solly-specialpower">
          <!-- SOLLY SPECIALPOWER TITLE -->
          <div class="relative w-full flex justify-center">
            <img src="../../public/imges/specialpower.png" style="width: 50%; height: auto;" class="lazy">
          </div>
          <!-- NINE SPECIALPOWER -->
          <div class="grid grid-cols-12 gap-3 justify-center mx-[18%] mt-[3%] sm:gap-12">
            <div class="col-span-4 mb-[5%]">
              <img src="../../public/imges/specialskill1.png" width="70%" height="auto" class="lazy relative mx-auto" />
            </div>
            <div class="col-span-4">
              <img src="../../public/imges/specialskill2.png" width="93%" height="auto" class="lazy relative mx-auto" />
            </div>
            <div class="col-span-4 relative -top-2">
              <img src="../../public/imges/specialskill3.png" width="90%" height="auto" class="lazy relative mx-auto" />
            </div>
            <div class="col-span-4 mb-[5%]">
              <img src="../../public/imges/specialskill4.png" width="82%" height="auto" class="lazy relative mx-auto" />
            </div>
            <div class="col-span-4">
              <img src="../../public/imges/specialskill5.png" width="80%" height="auto" class="lazy relative mx-auto" />
            </div>
            <div class="col-span-4 relative -top-4">
              <img src="../../public/imges/specialskill6.png" width="82%" height="auto" class="lazy relative mx-auto" />
            </div>
            <div class="col-span-4">
              <img src="../../public/imges/specialskill7.png" width="90%" height="auto" class="lazy relative mx-auto" />
            </div>
            <div class="col-span-4">
              <img src="../../public/imges/specialskill8.png" width="80%" height="auto" class="lazy relative mx-auto" />
            </div>
            <div class="col-span-4">
              <img src="../../public/imges/specialskill9.png" width="90%" height="auto" class="lazy relative mx-auto" />
            </div>
          </div>
          <!-- going planet -->
          <div class="grid grid-cols-12 mx-[10%] mt-[15%]">
            <div class="col-span-8 mt-[5%] ml-[10%]">
              <img src="../../public/imges/goingsollyplanet.png" width="80%" height="auto" class="lazy relative mx-auto" />
            </div>
            <div class="col-span-4 ml-[10%] mr-[20%]">
              <img src="../../public/imges/gotosee.png" width="100%" height="auto" class="lazy relative mx-auto cursor-pointer" @click="location('AISOLLY')"/>
            </div>
          </div>
          <!-- STAR IMG -->
          <div class="relative -left-[40%] -top-[10%]">
            <img src="../../public/imges/star4.png" width="3%" height="auto" class="lazy relative mx-auto" />
          </div>
          <!-- Communication Gate Title -->
          <div class="relative mt-[12%] mx-[10%]">
            <img src="../../public/imges/text3.png" width="30%" height="auto" class="lazy relative mx-auto" />
          </div>
          <!-- Youtube video -->
          <div class="relative flex justify-center z-10">
            <iframe width="70%" height="170" class="sm:h-[300px] md:h-[350px] lg:h-[480px] xl:h-[577px]" src="https://www.youtube.com/embed/h_Elrr9pYrc" title="溝通很簡單，但對他們很難｜AI Solly特殊生社交溝通訓練公益贊助計畫" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <!-- 3 -->
      <div class="relative">
        <!-- 背景圖 -->
        <div class="relative">
          <img src="../../public/imges/bg2-3.png" style="width: 100%; height: auto;" class="lazy">
        </div>
        <!-- SOLLY JOURNEY -->
        <div class="absolute top-[15%] w-full" id="solly-journey">
          <div class="relative">
            <img src="../../public/imges/sollyjourney.png" width="45%" height="auto" class="lazy relative mx-auto" />
          </div>
          <div class="flex mt-[5%] justify-center">
            <div class="flex justify-center">
              <img src="https://i.imgur.com/iDTuOHI.png" width="80%" height="auto" class="lazy" />
            </div>
          </div>
        </div>
      </div>
      <!-- 4 -->
      <div class="relative">
        <!-- 背景圖 -->
        <div class="relative">
          <img src="../../public/imges/bg2-4.png" style="width: 100%; height: auto;" class="lazy">
        </div>
        <div class=" absolute top-[12%] w-full" id="our-story">
          <!-- 我們的故事 -->
          <div class="relative">
            <img src="../../public/imges/ourstory.png" style="width: 35%; height: auto; " class="lazy relative mx-auto">
          </div>
          <div class="relative">
            <img src="../../public/imges/sollyfrom.png" style="width: 70%; height: auto; " class="lazy relative mx-auto">
          </div>
        </div>
      </div>
      <!-- 5 -->
      <div class="relative">
        <!-- 背景圖 -->
        <div class="relative">
          <img src="../../public/imges/bg2-5.png" style="width: 100%; height: auto;" class="lazy">
        </div>
        <!-- 成長歷程 -->
        <div class=" absolute -top-[3%] w-full">
          <div class="relative">
            <img src="../../public/imges/growingup.png" style="width: 80%; height: auto; " class="lazy relative">
          </div>
          <div class="relative z-10">
            <img src="../../public/imges/teams.png" style="width: 65%; height: auto; " class="lazy relative mx-auto">
          </div>
        </div>
        <!-- 裝飾 -->
        <div class=" absolute top-[7%] -right-[4%] w-full flex justify-end">
            <img src="../../public/imges/ufo.png" style="width: 14%; height: auto; " class="lazy relative">
        </div>
      </div>
      <!-- 6 -->
      <div class="relative">
        <!-- 背景圖 -->
        <div class="relative">
          <img src="../../public/imges/bg2-6.png" style="width: 100%; height: auto;" class="lazy">
        </div>
        <div class=" absolute top-[12%] w-full">
          <img src="../../public/imges/store.png" style="width: 70%; height: auto; " class="lazy relative mx-auto">
        </div>
      </div>
    </main>
    <footer id="contact-us">
      <div class="relative">
        <!-- 背景圖 -->
        <div class="relative">
          <img src="../../public/imges/footer.png" style="width: 100%; height: auto;" class="lazy">
        </div>
        <!-- 公司資訊 -->
        <div class="absolute top-[17%] md:top-[19%] xl:top-[20%] left-[10%] text-white font-sans font-black">
          <h2 class=" text-lg md:text-4xl lg:text-5xl">
            索力星球股份有限公司
          </h2>
          <div class=" flex mt-[3%] sm:mt-[10%] md:mt-[6%] lg:mt-[10%] xl:mt-[18%]">
            <div class= "w-[4%] sm:w-[6%] lg:w-[8%]">
              <img src="../../public/imges/location.png" style="width: 100%; height: auto;" alt="Location icon" class="lazy" />
            </div>
            <div class="relative left-[2%] xl:left-[5%] lg:mt-[1%] text-xs md:text-xl lg:text-2xl">桃園市中壢區新中北路 449 號 3 樓</div>
          </div>
          <div class=" flex mt-[2%] md:mt-[3%] lg:[5%] xl:mt-[7%]">
            <div class= "w-[4%] sm:w-[6%] lg:w-[8%]">
              <img src="../../public/imges/phone.png" style="width: 100%; height: auto;" alt="Phone icon" class="lazy lg:w-[10%]" />
            </div>
            <div class="relative left-[2%] xl:left-[5%] lg:mt-[1%] text-xs md:text-xl lg:text-2xl">(03) 265-1856</div>
          </div>
          <div class=" flex mt-[2%] md:mt-[3%] xl:mt-[7%]">
            <div class= "w-[4%] sm:w-[6%] lg:w-[8%]">
              <img src="../../public/imges/clock.png" style="width: 100%; height: auto;" alt="Clock icon" class="lazy lg:w-[10%]" />
            </div>
            <div class="relative left-[2%] xl:left-[5%] lg:mt-[1%] text-xs md:text-xl lg:text-2xl">Mon. ~ Fri.｜ 9：00 ~ 17：00</div>
          </div>
          <div class=" flex mt-[2%] md:mt-[3%] xl:mt-[7%]">
            <div class= "w-[4%] sm:w-[6%] lg:w-[8%]">
              <img src="../../public/imges/mail.png" style="width: 100%; height: auto;" alt="Mail icon" class="lazy lg:w-[10%]" />
            </div>
            <div class="relative left-[2%] xl:left-[5%] lg:mt-[1%] text-xs md:text-xl lg:text-2xl">service@cosmofii.com</div>
          </div>
        </div>
        <!-- 粉專連結 -->
        <div class=" absolute bottom-[8%] left-[9%] flex">
          <div class="relative mx-1 sm:mx-2 md:mx-3 lg:mx-2 w-[5%] sm:w-[7.5%] md:w-[7.5%] lg:w-[6%] xl:w-[4%]">
            <a href="https://www.facebook.com/PolaKids" target="_blank">
              <img src="../../public/imges/fb.png" height="auto" width="100%" class="lazy relative">
            </a>
          </div>
          <div class="relative mx-1 sm:mx-2 md:mx-3 lg:mx-2 w-[5%] sm:w-[7.5%] md:w-[7.5%] lg:w-[6%] xl:w-[4%]">
            <a href="https://www.instagram.com/polakids.aisolly?igsh=b2g5b2lkeXI4NmQ5" target="_blank">
              <img src="../../public/imges/ig.png" height="auto" width="100%" class="lazy relative">
            </a>
          </div>
          <div class="relative mx-1 sm:mx-2 md:mx-3 lg:mx-2 w-[5%] sm:w-[7.5%] md:w-[7.5%] lg:w-[6%] xl:w-[4%]">
            <a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=396vgpvr" target="_blank">
              <img src="../../public/imges/line.png" height="auto" width="100%" class="lazy relative">
            </a>
          </div>
        </div> 
        <!-- 公司徽章 -->
        <div class=" absolute bottom-[8%] right-[8%] w-[25%]">
          <img src="../../public/imges/Sollyicon.png" height="auto" width="100%" class="lazy relative">
        </div>
      </div>
    </footer>
  </div>
</template>
  
<script>
  export default {
    name: 'SollyLandingPage',
  data() {
    return {
      isImageVisible: false,
      currentIndex: null,
      mobileMenuOpen: false,
    };
  },
  methods: {
    showImage(index) {
      this.currentIndex = index;
      this.isImageVisible = true;
    },
    hideImage() {
      this.isImageVisible = false;
      this.currentIndex = null;
    },
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    location(target) {
      if(target == 'Home'){
        window.location.href = '/';
      }else if(target == 'AISOLLY'){
         window.open('https://support.polakids.com.tw/', '_blank');
      }
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    handleClickOutside(event) {
      const mobileNav = this.$refs.mobileNav;
      if (mobileNav && !mobileNav.contains(event.target)) {
        this.mobileMenuOpen = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>
  
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
  